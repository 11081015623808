.PersonalFormGroup___1d5pp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  flex: 1; }

@media only screen and (min-width: 769px) {
  .PersonalFormGroup___1d5pp {
    flex-direction: row;
    align-items: flex-end; } }
