.Modal___IblYg {
  background-color: #f9f9f9; }

.Title___38BMh {
  color: #767676; }

.ThemeButton___32Uww {
  background-color: #0b8d8c;
  border: none;
  border-radius: 0; }
  .ThemeButton___32Uww:hover {
    background-color: #0b8d8c;
    box-shadow: none; }
  .ThemeButton___32Uww:focus, .ThemeButton___32Uww:active, .ThemeButton___32Uww:not(:disabled):not(.disabled___TCz3g):active:focus {
    background-color: #0b8d8c;
    box-shadow: none;
    border: none; }
  .ThemeButton___32Uww:disabled {
    background-color: #c9c9c9; }
