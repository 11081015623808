@media only screen and (min-width: 769px) {
  .SidebarDependent___1WYB1[data-expanded="true"] {
    margin-left: 238px; }
  .SidebarDependent___1WYB1[data-expanded="false"] {
    margin-left: 60px; } }

.DefaultStyle___2Dc3d {
  padding-top: 200px; }
  .DefaultStyle___2Dc3d nav {
    line-height: 1; }

@media only screen and (min-width: 426px) {
  .DefaultStyle___2Dc3d {
    padding-top: 215px; } }

@media only screen and (min-width: 581px) {
  .DefaultStyle___2Dc3d {
    padding-top: 220px; } }

@media only screen and (min-width: 769px) {
  .DefaultStyle___2Dc3d {
    padding-top: 180px; } }

@media only screen and (min-width: 1025px) {
  .DefaultStyle___2Dc3d {
    padding-top: 210px; } }

@media only screen and (min-width: 1441px) {
  .DefaultStyle___2Dc3d {
    padding-top: 230px; } }

@media only screen and (min-width: 1841px) {
  .DefaultStyle___2Dc3d {
    padding-top: 270px; } }

@media only screen and (min-width: 2561px) {
  .DefaultStyle___2Dc3d {
    padding-top: 290px; } }
