.TopBar___3VN9p {
  background-color: #ffffff; }

.BrandLogo___2udFj {
  width: 100%;
  max-width: 15rem; }

.BrandBanner___2RESO {
  width: 100%;
  max-width: 100%; }

.NotificationBtn___KZTY_ {
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: #0a8d8c; }
  .NotificationBtn___KZTY_:not(:disabled):not(.disabled___3Gh4G):active:focus {
    box-shadow: none;
    background-color: transparent;
    border: none; }
  .NotificationBtn___KZTY_:focus, .NotificationBtn___KZTY_:active, .NotificationBtn___KZTY_:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #0a8d8c; }
  .NotificationBtn___KZTY_:hover {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out; }

.Popover___3Fr1k {
  width: 25rem;
  background-color: #ffffff; }

.PopoverHeader___3P3ND {
  background-color: #ffffff;
  color: #0a8d8c;
  padding-left: 1rem; }

.PopoverBody___11Jai {
  padding: 0;
  max-height: 350px;
  overflow-y: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .PopoverBody___11Jai::-webkit-scrollbar {
    width: 10px; }
  .PopoverBody___11Jai::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .PopoverBody___11Jai::-webkit-scrollbar-thumb {
    background: #888; }
  .PopoverBody___11Jai::-webkit-scrollbar-thumb:hover {
    background: #555; }

.ReadAll___2RVoW {
  cursor: pointer; }
  .ReadAll___2RVoW:hover {
    padding: 2px;
    text-decoration: underline; }

.NotificationCard___XOVbC {
  border-radius: 0;
  border-left: none;
  border-right: none;
  cursor: pointer; }

.ReadNotification___1g8nN {
  background-color: #f7f7f7; }

.UnreadNotification___cSeCq {
  background-color: #d4e8e8; }

.NotificationHeader___1rpZB {
  padding-left: 1rem;
  font-size: 14px;
  color: #6d6d6d;
  margin-bottom: 2px;
  margin-top: 2px; }

.NotificationBody___33ErU {
  border-radius: 0;
  border: none;
  border-left: none;
  border-right: none;
  padding-top: 2px;
  padding-bottom: 8px; }

.NotificationIcon___HJQqT:hover {
  color: #0a8d8c; }

.TopNavBarStyle___3bcG2 {
  display: block; }

@media only screen and (min-width: 769px) {
  .TopNavBarStyle___3bcG2 {
    display: none; } }
