.buttonStyle____-B64 {
  border-radius: 0; }

.HeaderOuterRow___3AbDC {
  display: flex;
  justify-content: center; }

.ListingTableContainer___1agAi {
  padding: 30px 20px;
  margin-top: 30px;
  margin-bottom: 100px;
  background-color: #eeeeee; }

.CardListingContainer___kmpnV {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto; }

.CategoryLogo___1cNHH {
  fill: #0b8d8c; }

.DividerRow___3d6Dj {
  border: none !important; }

.LogoDefaultStyle___1fmFP {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center; }

.CardTableHeader___w1h8f {
  padding: 0;
  max-width: 115px; }

.ActiveWatch___3AM4j {
  color: #0b8d8c; }
  .ActiveWatch___3AM4j:hover {
    color: #d6d6d6; }

.WatchIcon___2-ldV {
  color: #d6d6d6; }
  .WatchIcon___2-ldV:hover {
    color: #0b8d8c; }

.WatchIconBtn___1zVTA {
  background-color: transparent;
  border: none;
  box-shadow: none; }
  .WatchIconBtn___1zVTA:not(:disabled):not(.disabled___1DJuH):active:focus, .WatchIconBtn___1zVTA:not(:disabled):not(.disabled___1DJuH):active {
    border: none;
    background-color: transparent;
    box-shadow: none; }
  .WatchIconBtn___1zVTA:focus, .WatchIconBtn___1zVTA:hover, .WatchIconBtn___1zVTA:active {
    border: none;
    background-color: transparent;
    box-shadow: none; }

.TableTd___1Dv21 {
  background-color: #ffffff;
  padding-bottom: 4px !important;
  padding-top: 5px !important;
  vertical-align: middle !important;
  text-align: center; }

.SpacingTrTd___3f5mD {
  padding-bottom: 4 !important; }

.CardTableHead___1l_hc {
  padding-bottom: 30px !important;
  text-align: center;
  color: gray;
  font-weight: normal; }

.ListingCardRow___3Pr14 {
  margin-top: 10px;
  margin-bottom: 10px; }

.DocumentWrapper___3g_0F {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9; }

.DocumentDownloadBtn___3jGZs {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff; }
  .DocumentDownloadBtn___3jGZs:hover {
    color: #fff; }

.SelfBid___RidNw {
  background: #bbded3 !important; }

.DocumentCardBody___30NRP {
  overflow-y: auto;
  height: 378px;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .DocumentCardBody___30NRP::-webkit-scrollbar {
    width: 10px; }
  .DocumentCardBody___30NRP::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .DocumentCardBody___30NRP::-webkit-scrollbar-thumb {
    background: #888; }
  .DocumentCardBody___30NRP::-webkit-scrollbar-thumb:hover {
    background: #555; }

.cardWrapper___2MRJa {
  margin-top: 10px;
  margin-bottom: 10px;
  border: none !important;
  cursor: pointer; }
  .cardWrapper___2MRJa:hover {
    box-shadow: -4px 4px 12px 4px #cec2c2; }

.DetailsMainListingLayout___1eB5T {
  display: flex; }

.MiniCardPositionedWrapper___3N0Sh {
  position: absolute;
  left: -23px;
  height: 100%;
  display: flex;
  align-items: center; }

.MiniCardPositionContent___3N1pR {
  background: #0b8d8c;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 60px;
  color: #5f5f5f;
  font-size: 16px;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  box-shadow: #cec2c2 0px 0px 4px; }

.CardRowWrapper___2FLbS {
  align-items: center;
  text-align: center;
  height: 100%; }

.MiniCard___3w832 {
  background: none; }

.MiniCardHeader___1kKH0 {
  border: none;
  padding-bottom: 0;
  background: none; }

.ReOpenFlagWrapper___2MsGh {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px; }
  .ReOpenFlagWrapper___2MsGh[data-status="cancel"] {
    background: #ffc107; }
  .ReOpenFlagWrapper___2MsGh[data-status="accept"] {
    background: #0b8d8c; }

.Accept___1pR55 {
  background: #0b8d8c; }

.ReOpenContentWrapper___icyfX {
  display: flex;
  justify-content: center; }

.ConfirmationModalBody___5VKYG {
  background: #f9f9f9 !important; }

.MiniCardBody___224vG {
  padding-bottom: 10px;
  padding-top: 10px; }

.LargeCard___1ux4g {
  background: none; }

.LargeCardHeader___1F8uz {
  border: none;
  background: #f9f9f9; }

.LargeCardBody___OtjBG {
  background-color: #fff; }

.DetailsMiniCardBody___2vyee {
  background: #eff3f7;
  opacity: 0.7; }

.CertificateRow___2B_Sh {
  flex-wrap: wrap; }

.CertificateImage___2sM6a {
  max-width: 40px;
  width: 100%; }

.CardMiniModalStyle___27khk {
  top: 15%; }

.CardListingTableLoader___3-Lr0 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px; }

.TabActive___1t_vN {
  background-color: transparent !important;
  color: #c7c8ce !important;
  fill: #c7c8ce !important; }

.TabLink___1PkT_ {
  color: gray; }
  .TabLink___1PkT_:hover {
    color: #0b8d8c; }

.LargeDetailBody___28myt {
  background-color: #fff;
  overflow-x: hidden;
  height: 422px;
  max-height: 422px;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .LargeDetailBody___28myt::-webkit-scrollbar {
    width: 10px; }
  .LargeDetailBody___28myt::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .LargeDetailBody___28myt::-webkit-scrollbar-thumb {
    background: #888; }
  .LargeDetailBody___28myt::-webkit-scrollbar-thumb:hover {
    background: #555; }

.DetailsCard___1oaqa {
  flex: 1;
  margin-bottom: 5px;
  margin-top: 5px; }

.EnhaunceHead___49xXp {
  color: #929292; }

.EnhaunceValue___1YaKh {
  color: #229a77; }

.DetailBidBtn___1GfdL {
  width: 100%;
  height: 100%; }

.buttonwrapper___211D- {
  margin-top: 5px; }

@media only screen and (min-width: 769px) {
  .buttonwrapper___211D- {
    margin-top: 0px; } }

.BiddingCard___387t6 {
  margin-top: 30px;
  margin-bottom: 60px; }

.BiddingCardBody___RAzZF {
  background-color: #fff; }

.EmptyBid___qZpi4 {
  font-size: 20px;
  color: #a5a5a5; }

.BiddIngInfoCard___1ul4H {
  background: #f9f9f9;
  margin-top: 10px;
  margin-bottom: 10px; }

.SelfBiddingCard___3ndND {
  background: #e1e8e1;
  margin-top: 10px;
  margin-bottom: 10px; }

.ListingBiddingInfo___3gnMn {
  background-color: #eeeeee;
  border-radius: 0;
  height: 100%; }

.BrandColor___1notJ {
  color: #0b8d8c; }

.buttonStyle____-B64, .DocumentDownloadBtn___3jGZs, .DetailBidBtn___1GfdL {
  background-color: #0b8d8c;
  border: none; }
  .buttonStyle____-B64:hover, .DocumentDownloadBtn___3jGZs:hover, .DetailBidBtn___1GfdL:hover {
    background-color: #0b8d8c;
    box-shadow: none; }
  .buttonStyle____-B64:focus, .DocumentDownloadBtn___3jGZs:focus, .DetailBidBtn___1GfdL:focus, .buttonStyle____-B64:active, .DocumentDownloadBtn___3jGZs:active, .DetailBidBtn___1GfdL:active, .buttonStyle____-B64:not(:disabled):not(.disabled___1DJuH):active:focus, .DocumentDownloadBtn___3jGZs:not(:disabled):not(.disabled___1DJuH):active:focus, .DetailBidBtn___1GfdL:not(:disabled):not(.disabled___1DJuH):active:focus {
    background-color: #0b8d8c;
    box-shadow: none;
    border: none; }
  .buttonStyle____-B64:disabled, .DocumentDownloadBtn___3jGZs:disabled, .DetailBidBtn___1GfdL:disabled {
    background-color: #c9c9c9; }

.ButtonLoader___3i7bO {
  display: flex;
  align-items: center;
  justify-content: center; }

.TableResets___1BJwy, .TableTd___1Dv21, .SpacingTrTd___3f5mD, .CardTableHead___1l_hc {
  border-bottom: 0 !important;
  border-top: 0 !important; }

.MiniCard___3w832, .LargeCard___1ux4g, .BiddingCard___387t6 {
  box-shadow: -1px 1px 7px 1px #d3d3d3;
  background: none; }

.MiniCardHeader___1kKH0, .LargeCardHeader___1F8uz {
  font-size: 16px;
  color: #797979; }

.MiniCardBody___224vG, .LargeCardBody___OtjBG, .LargeDetailBody___28myt {
  font-size: 18px;
  color: #525252; }

.CertificateRow___2B_Sh {
  display: flex;
  justify-content: center; }

.DetailsMainListingLayout___1eB5T, .MainListingLayout___3rnDv {
  background-color: #f9f9f9;
  height: 100%; }
